<template>
  <v-data-table
    :headers="headers"
    :items="selectedFrames"
    class="elevation-1 neo-table-checkout"
    :items-per-page="Number(15)"
    mobile-breakpoint="0"
    :hide-default-footer="selectedFrames.length <= 15"
    :no-data-text="''"
    :footer-props="{
      'items-per-page-text': $t('rowsPerPage'),
      'items-per-page-all-text': $t('all'),
    }"
    :items-per-page-all-text="$t('all')"
  >
    <!-- group-by="title" -->
    <template v-slot:[`item.frame`]="{ item }">
      <div class="d-flex align-items center frame-image">
        <div class="ma-1">
          <img :src="item.frame" height="50px" @click="onCoordinateClicked(item)"/>
        </div>
        <button @click="onCoordinateClicked(item)">
          <v-icon size="16" class="ml-2" role="button" color="primary">
            mdi-eye
          </v-icon>
        </button>
      </div>
    </template>
    <!-- <template v-slot:[`item.currentValue`]="{ item }">
      {{ formatAmount(item.currency, item.currentValue + item.currentValue*tax) }}
    </template> -->

    <template v-slot:[`body.append`]="{ headers }">
      <tr>
        <td v-for="(header, i) in headers" :key="i">
          <div class="text-right" v-if="header.value == 'currentValue'">
            <strong class="cypher cypher--tiny">
              {{ formatAmount(currency, totalValue + totalValue*tax) }}</strong
            >
          </div>

          <!-- <div class="text-center" v-else-if="header.value == 'coordinates_formatted'">
            <strong>{{ $t('total').toUpperCase() }}</strong>
          </div>

          <div v-else>
         
          </div> -->
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import FramesDS from '@/services/FramesDS';
import helpers from '@/mixins/helpers.js';

export default {
  name: 'Checkout2Summary',
  mixins: [helpers],
  props: {
    selectedFrames: {
      type: Array,
      default: () => {
        return [{ frame: '', value: '', currency: 'jpy' }];
      },
    },
    totalValue: {
      type: Number,
      default: 0,
    },
    tax: {
      type: Number,
      default: 0.1,
    },
    currency: { type: String, default: 'jpy' },
  },
  data() {
    return {
      workInfo: {},
      startTime: 0,
      endTime: 0,
      countdown: 0,
      intervalId: 0,
      totalMarketPrice: 0,
      data: [],
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t('frame'), value: 'frame', width: '25%', sortable: false, align: 'center', class: "table-header-custom" },
        { text: this.$t('coordinates'), value: 'coordinates_formatted', width: '25%', sortable: false, align: 'center', class: "table-header-custom" },
        { text: this.$t('frameId'), value: 'frameIdPublic', width: '50%', sortable: false, align: 'center', class: "table-header-custom" },
        // {
        //   text: this.$t('price'),
        //   value: 'currentValue',
        //   align: 'right',
        //   width: '40%',
        // },
      ];
    },
  },
  methods: {
    deleteAllBlocked() {
      FramesDS.deleteAllBlocked(this.$store.state.user.uid);
      this.endCountdown();
    },

    // If user has abandonded shooping frames, countdown before frames are unblocked
    startCountdown(endTime) {
      this.$store.commit('startCountdown', endTime);
    },
    endCountdown() {
      this.$store.commit('endCountdown');
    },
    totalValues() {
      this.totalMarketPrice = 0;
      this.totalValuePaid = 0;
      this.$store.state.selectedFrames.forEach((v) => {
        this.totalMarketPrice += v.marketPrice;
        this.totalValuePaid += v.value;
      });
      this.$store.commit('setTotalValuePaid', this.totalMarketPrice);
    },
    onCoordinateClicked(value) {
      this.$emit('coordinateClicked', value.coordinates);
    },
  },
  mounted() {
    this.endCountdown();
    // console.log(this.quantity);
    this.totalMarketPrice = 0;
    this.totalValuePaid = 0;

    this.totalValues();
  },
};
</script>
<style lang="scss">
.neo-table-checkout table {
  table-layout: fixed;
  padding: 0px;
  td {
    padding:0px !important;
  }
  th {
    text-align: right;
  }
}
.table-header-custom {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
<style scoped lang="scss">




.frame-image {
  position: relative;
  justify-content: center;
  button {
    position: absolute;
    top: 2px;
    margin-left:-38px;
    .v-icon {
      text-shadow: 2px 2px 5px rgba(10, 22, 70, 1);
    }
  }
}
</style>
