var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 neo-table-checkout",attrs:{"headers":_vm.headers,"items":_vm.selectedFrames,"items-per-page":Number(15),"mobile-breakpoint":"0","hide-default-footer":_vm.selectedFrames.length <= 15,"no-data-text":'',"footer-props":{
    'items-per-page-text': _vm.$t('rowsPerPage'),
    'items-per-page-all-text': _vm.$t('all'),
  },"items-per-page-all-text":_vm.$t('all')},scopedSlots:_vm._u([{key:"item.frame",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items center frame-image"},[_c('div',{staticClass:"ma-1"},[_c('img',{attrs:{"src":item.frame,"height":"50px"},on:{"click":function($event){return _vm.onCoordinateClicked(item)}}})]),_c('button',{on:{"click":function($event){return _vm.onCoordinateClicked(item)}}},[_c('v-icon',{staticClass:"ml-2",attrs:{"size":"16","role":"button","color":"primary"}},[_vm._v(" mdi-eye ")])],1)])]}},{key:"body.append",fn:function(ref){
  var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(header,i){return _c('td',{key:i},[(header.value == 'currentValue')?_c('div',{staticClass:"text-right"},[_c('strong',{staticClass:"cypher cypher--tiny"},[_vm._v(" "+_vm._s(_vm.formatAmount(_vm.currency, _vm.totalValue + _vm.totalValue*_vm.tax)))])]):_vm._e()])}),0)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }